@import '../../styles/mixin';

.LiaPageLayout {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100vh;
  position: relative;
  justify-content: space-between;
}

.LiaPageHeader {
  height: 10%;
  background-color: #123285;
  background-image: url('../../assets/icons/life-logo-white.svg');
  background-size: 33% 50%;
  background-repeat: no-repeat;
  background-position: center;
  @include screen-xs {
    background-size: 90% 50%;
  }

  @include screen-sm {
    background-size: 80% 50%;
  }

  @include screen-md {
    background-size: 70% 50%;
  }
}

.LiaPageBody {
  height: 90%;
  background-color: rgba(236, 238, 240, 1);

  display: flex;
  flex-flow: column;
  align-items: center;

  .LiaPageErrorIcon {
    flex: 2.5;
    height: 100%;
    width: 100%;
    margin: 2rem;
    background-image: url('../../assets/icons/lia-error-page.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .LiaPageMainBlock {
    flex: 4.5;
    width: 65%;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 10px 0px rgba(9, 13, 29, 0.16);
    padding: 30px 40px 50px;

    @include screen-xs {
      padding: 15px;
      width: 85%;
    }

    @include screen-sm {
      padding: 15px;
      width: 85%;
    }

    @include screen-md {
      padding: 25px;
      width: 75%;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &_Block1 {
      flex: 1;
      width: 60%;
      margin-bottom: 1.5rem;

      @include screen-xs {
        width: 100%;
      }

      @include screen-sm {
        width: 100%;
      }

      @include screen-md {
        width: 80%;
      }
    }

    &_Block2 {
      flex: 1;
      width: 90%;
      margin-bottom: 2rem;

      @include screen-xs {
        width: 100%;
      }

      @include screen-sm {
        width: 100%;
      }

      @include screen-md {
        width: 95%;
      }
    }

    &_Block3 {
      flex: 1;
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &_Link {
        padding: 0.5rem 1.5rem;
        border-radius: 7px;
        color: white;
        background: rgba(27, 56, 158, 1);
        font-family: Open Sans, serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0;

        @include screen-xs {
          border-radius: 4px;
          line-height: 18px;
          font-size: 16px;
        }

        @include screen-sm {
          border-radius: 4px;
          line-height: 20px;
          font-size: 18px;
        }

        @include screen-md {
          border-radius: 5px;
          line-height: 24px;
          font-size: 20px;
        }
      }
    }

    &_Block4 {
      flex: 1;
      width: 80%;

      @include screen-xs {
        width: 100%;
      }

      @include screen-sm {
        width: 100%;
      }

      @include screen-md {
        width: 90%;
      }
    }

    &_Text {
      font-family: Open Sans, serif;
      font-size: 24px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0;
      text-align: center;

      @include screen-xs {
        line-height: 18px;
        font-size: 16px;
      }

      @include screen-sm {
        line-height: 20px;
        font-size: 18px;
      }

      @include screen-md {
        line-height: 24px;
        font-size: 20px;
      }

      &__Title {
        font-weight: 700;
        color: rgba(18, 50, 133, 1);
      }
    }
  }

  .LiaPageTeamRespect {
    flex: 1.5;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 2rem;
    border-bottom: 1px solid rgba(11, 50, 152, 1);

    font-family: Open Sans, serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
    text-align: center;

    @include screen-xs {
      width: 90%;
      flex: 1;
      padding: 1.5rem;
      line-height: 18px;
      font-size: 16px;
    }

    @include screen-sm {
      width: 80%;
      flex: 1;
      padding: 1.5rem;
      line-height: 20px;
      font-size: 18px;
    }
    @include screen-md {
      width: 70%;
      flex: 1;
      padding: 1.5rem;
      line-height: 24px;
      font-size: 20px;
    }
  }

  .LiaPageCompanyInfo {
    flex: 1;
    width: 90%;
    background: url('../../assets/icons/life-logo-blue.svg') no-repeat right;
    background-size: 33% 50%;
    display: flex;
    flex-direction: column;
    padding: 50px 10px 10px;

    font-family: Open Sans, serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;

    @include screen-xs {
      padding: 20px 10px 50px;
      background: url('../../assets/icons/life-logo-blue.svg') no-repeat bottom;
      background-size: 70% 50%;
      line-height: 14px;
      font-size: 12px;
    }

    @include screen-sm {
      padding: 30px 10px 50px;
      background: url('../../assets/icons/life-logo-blue.svg') no-repeat bottom;
      background-size: 45% 50%;
      line-height: 16px;
      font-size: 14px;
    }
    @include screen-md {
      padding: 40px 10px 50px;
      background: url('../../assets/icons/life-logo-blue.svg') no-repeat bottom;
      background-size: 33% 50%;
      line-height: 18px;
      font-size: 16px;
    }

    &_Row {
      margin: 1px;
    }
  }
}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 300;
    src: local("Open Sans"), local("OpenSans"), url("../assets/fonts/Open_Sans/OpenSans-Light.ttf") format("truetype"); }
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src: local("Open Sans"), local("OpenSans"), url("../assets/fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype"); }
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    src: local("Open Sans"), local("OpenSans"), url("../assets/fonts/Open_Sans/OpenSans-SemiBold.ttf") format("truetype"); }
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    src: local("Open Sans"), local("OpenSans"), url("../assets/fonts/Open_Sans/OpenSans-Bold.ttf") format("truetype"); }
  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    src: local("Open Sans"), local("OpenSans"), url("../assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf") format("truetype"); }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 100;
    src: local("Montserrat"), url("../assets/fonts/Montserrat/Montserrat-Thin.ttf") format("truetype"); }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 200;
    src: local("Montserrat"), url("../assets/fonts/Montserrat/Montserrat-ExtraLight.ttf") format("truetype"); }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    src: local("Montserrat"), url("../assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype"); }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat"), url("../assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype"); }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    src: local("Montserrat"), url("../assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype"); }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    src: local("Montserrat"), url("../assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype"); }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: local("Montserrat"), url("../assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype"); }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    src: local("Montserrat"), url("../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype"); }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    src: local("Montserrat"), url("../assets/fonts/Montserrat/Montserrat-Black.ttf") format("truetype"); }
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    src: local("Roboto"), url("../assets/fonts/Roboto/Roboto-Thin.ttf") format("truetype"); }
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    src: local("Roboto"), url("../assets/fonts/Roboto/Roboto-Light.ttf") format("truetype"); }
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: local("Roboto"), url("../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype"); }
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src: local("Roboto"), url("../assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype"); }
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: local("Roboto"), url("../assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype"); }
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    src: local("Roboto"), url("../assets/fonts/Roboto/Roboto-Black.ttf") format("truetype"); }
  @font-face {
    font-family: "icomoon";
    font-style: normal;
    font-weight: normal;
    src: local("icomoon"), url("../assets/fonts/icomoon/icomoon.eot");
    src: local("icomoon"), url("../assets/fonts/icomoon/icomoon.eot#iefix") format("embedded-opentype"), url("../assets/fonts/icomoon/icomoon.ttf") format("truetype"), url("../assets/fonts/icomoon/icomoon.woff") format("woff"), url("../assets/fonts/icomoon/icomoon.svg#icomoon") format("svg"); }
  
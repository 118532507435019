.NotFountContainer {
  height: 50vh;
  max-width: 1500px;
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;
  color: #06408C;
  .NotFountH1 {
    font-size: 40px;
  }
  .NotFountH2 {
    font-size: 30px;
  }
}

* {
    box-sizing: border-box;
}

html, body {
    width: 100%;
    min-width: 320px;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

#root {
    display: block;
    height: 100%;
}

// React-Datepicker

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-popper {
    z-index: 50;
}

.react-datepicker__header--time {
    display: none;
}

.react-datepicker__time-container, .react-datepicker__time, .react-datepicker__time-box, .react-datepicker__time-list, .react-datepicker__time-list-item {
    border-radius: 0.3rem;
}

.ToggleWrapper {
  display: block;
  width: 100%;
  border: 1px solid #DFE2EE;
  border-radius: 6px;
}

.ToggleWrapperToggle {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  background: #F8F8FA;
  border-radius: 6px;
  width: 100%;
  padding: 16px;
  .ToggleWrapperHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
  }
  .ToggleWrapperArrow {
    width: 12px;
    height: 8px;
    cursor: pointer;
    background-image: url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.28571L1.63636 8L6 3.42857L10.3636 8L12 6.28571L6 0L0 6.28571Z" fill="%230D63D6"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);
    transition: transform .3s ease-in-out;
  }
  &[data-active=true] {
    .ToggleWrapperArrow {
      transform: rotate(0);
    }
  }
  &[data-payment=false] {
    .ToggleWrapperArrow {
      background-image: none;
    }
  }
}

.ToggleWrapperInner {
  display: block;
  width: 100%;
  max-height: 99999px;
  visibility: visible;
  opacity: 1;
  transform: translateY(0%);
  transition: all .3s ease-in-out;

  &[data-active=false] {
    max-height: 0;
    visibility: hidden;
    opacity: 0;
    transform: translateY(0px);
  }

  &[data-payment=false] {
    max-height: 0;
  }

  .ToggleWrapperInnerContent {
    padding: 16px;
  }
}
@import './variables';

@mixin screen-xl {
    @media only screen and (min-width: $breakpoint-xl) {
        @content;
    }
}

@mixin screen-lg {
    @media only screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
        @content;
    }
}

@mixin screen-md {
    @media only screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
        @content;
    }
}

@mixin screen-sm {
    @media only screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        @content;
    }
}

@mixin screen-xs {
    @media only screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
        @content;
    }
}

@mixin screen($names...) {
    @each $name in $names {
        @if $name == 'xl' {
            @include screen-xl() {
                @content;
            }
        }

        @if $name == 'lg' {
            @include screen-lg() {
                @content;
            }
        }

        @if $name == 'md' {
            @include screen-md() {
                @content;
            }
        }

        @if $name == 'sm' {
            @include screen-sm() {
                @content;
            }
        }

        @if $name == 'xs' {
            @include screen-xs() {
                @content;
            }
        }
    }
}

@mixin spinner($color: rgba(255, 255, 255, .3)) {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border: 3px solid $color;
    border-radius: 50%;
    border-top-color: #FFFFFF !important;
    animation: loadingSpinner 1s ease-in-out infinite;

    @keyframes loadingSpinner {
        to { transform: rotate(360deg); }
    }
}

@mixin cross($color: #FFFFFF, $size: 10px) {
    display: inline-flex;
    justify-content: center;
    width: $size;
    height: $size;
    position: relative;
    cursor: pointer;

    &:before, &:after {
        content: '';
        display: block;
        width: 2px;
        height: $size;
        position: relative;
        background: $color;
    }

    &:before {
        transform: rotate(45deg);
        left: 1px;
    }

    &:after {
        transform: rotate(-45deg);
        right: 1px;
    }
}